@import "animations";

html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
  color: #151d29;
  background: linear-gradient(45deg, #eff4f9, #48b1bf);
  background-attachment: fixed;
}

#svgscene {
  top: 0;
  position: fixed;

  #pretext {
    transform-origin: 50% 50%;
    transform: scale(0.02);
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }

  #scene1 {
    path,
    polygon,
    ellipse {
      opacity: 0;
    }
  }

  #scene2 {
    opacity: 0;
    transform: scale(4) translate(150px, 0px);
    transform-origin: center;
  }

  #scene3 {
    opacity: 0;
  }

  #p1,
  #p2,
  #p3,
  #p4,
  #p5,
  #mrt,
  #plane,
  #sgcircle {
    transform-origin: 50% 50%;
  }

  #leftmonitor,
  #rightmonitor,
  #roomitems,
  #desktable {
    path,
    polygon,
    ellipse {
      opacity: 0;
      stroke: black;
      fill-opacity: 0;
      stroke-width: 0.5;
      stroke-linecap: round;
      stroke-linejoin: miter;
      stroke-miterlimit: 4;
      stroke-dashoffset: 0;
    }
  }

  #blenderscene,
  #reactlogo {
    transform-box: fill-box;
    transform-origin: center;
  }
  #leftwall,
  #rightwall,
  #floor,
  #rightmonitor,
  #leftmonitor,
  #desktable,
  #deskitems,
  #deskshadows {
    transform-origin: 50% 50%;
  }
}

.contact-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .profile-btn {
    position: relative;
    size: 5em;
    z-index: 10;

    :hover {
      cursor: pointer;
    }
  }
}
