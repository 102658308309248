@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, 1%);
  }
  60% {
    transform: translate(0, 2%);
  }
}
